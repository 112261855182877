<template>
  <div class="container-fluid px-4">
    <div class="row mb-3">
      <div class="col-12 pl-0">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Company'
}
</script>

<style scoped>

</style>
